<script setup lang="ts">
import type { HomeBanners } from "@/types";

const { banners, seoData, isShowAdventureBanner, jackpots, jackpotGames } = useHomePage();

useHead({
	title: () => seoData.value?.seoTitle || "",
	meta: [
		{
			name: "description",
			content: () => seoData.value?.seoDescription
		},
		{ name: "keywords", content: () => seoData.value?.seoKeywords || "" }
	]
});

const isGuest = useIsGuest();
const { data: appInitData } = useAppInitData();

const isCatalogGames = useState<boolean>("show-catalog-games", () => false);

onMounted(() => {
	isCatalogGames.value = false;
});
</script>
<template>
	<div :class="['page', { 'is-logged': !isGuest }]">
		<LazyOHomeBanners v-if="banners?.length && banners.length > 0" :banners="banners" class="section-item no-hidden" />

		<LazyOGamesCatalog :show-catalog="isCatalogGames" />

		<div v-if="!isCatalogGames" class="section-item page">
			<LazyOHomeSectionGames :itemSection="0" />

			<OLazyComponentNew id="home-game-guest-must-try">
				<LazyOHomeActivityFeed />
			</OLazyComponentNew>

			<LazyOHomeSectionGames :itemSection="1" hideOnLeave />

			<OLazyComponentNew id="home-promotions" :height="494">
				<OHomePromotions />
			</OLazyComponentNew>

			<LazyOHomeSectionGames :itemSection="2" hideOnLeave />

			<OLazyComponentNew v-if="isGuest" id="banner-wheel" class="wheels-banners">
				<LazyOHomeBannerWheel v-if="appInitData?.isShowDailyWheelForGuest" />
				<LazyOHomeBannerAdventure v-if="isShowAdventureBanner" />
			</OLazyComponentNew>

			<LazyOHomeSectionGames :itemSection="3" hideOnLeave />

			<OLazyComponentNew v-if="jackpots" id="home-jackpot" hideOnLeave>
				<LazyOHomeJackpot :game-mode="appInitData?.gameMode" :jackpots="jackpots" :games="jackpotGames" />
			</OLazyComponentNew>

			<LazyOHomeSectionGames :itemSection="4" hideOnLeave />

			<OLazyComponentNew v-if="isGuest" id="home-why-us">
				<LazyOHomeWhyUs />
			</OLazyComponentNew>

			<LazyOHomeSectionGames :itemSection="5" hideOnLeave />

			<OLazyComponentNew v-if="isGuest && banners?.length && banners.length > 0" id="banner-footer">
				<LazyOHomeBannerFooter :banner="banners?.[0] as HomeBanners[number]" />
			</OLazyComponentNew>

			<LazyOHomeSectionGames :itemSection="6" hideOnLeave />
		</div>
	</div>
</template>

<style lang="scss" scoped>
.section-item {
	max-width: 100%;
	overflow: hidden;
}

.no-hidden {
	overflow: inherit;
}

.page {
	display: flex;
	flex-direction: column;

	@include media-breakpoint-down(md) {
		gap: 32px;
	}
}

.wheels-banners {
	display: flex;
	flex-direction: column;
	gap: 32px;
	padding: 24px 0;

	@include media-breakpoint-down(md) {
		padding: 20px;
	}
}
</style>
